<template>
  <section class="breadcrumbs">
    <div class="container">
      <b-breadcrumb :items="items" class="pb-10"></b-breadcrumb>
    </div>
  </section>
</template>

<script>
export default {
  name: "Breadcrumbs",
  computed: {
    items() {
      const items = [];
      const bcrumbs = this.$store.getters["breadcrumbs/getBreadcrumbsRoutes"];
      let item = { text: "Home", to: "/" };
      items.push(item);
      bcrumbs.forEach((bcrumb) => {
        item = { text: bcrumb.name, to: bcrumb.route_link };
        items.push(item);
      });
      const curr = this.$store.getters["breadcrumbs/getBreadcrumbsCurrent"];
      item = { text: curr, active: true };
      items.push(item);
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  background-color: transparent;
  padding: 0;
  li {
    color: $black;
    a {
      color: $black;
    }
    + li {
      position: relative;
      padding-left: 20px;
      &:before,
      &:after {
        border-right: 2px solid;
        content: "";
        display: block;
        height: 8px;
        margin-top: -6px;
        position: absolute;
        transform: rotate(135deg);
        left: 10px;
        top: 50%;
        width: 0;
        padding: 0;
        color: currentColor;
      }
      &:after {
        margin-top: -1px;
        transform: rotate(45deg);
      }
    }
  }
   @media screen and (max-width:768px) {
    //  flex-wrap: nowrap;
     padding-top: 30px;
     li{
       + li{
         &:before,
         &:after{
           top: 10px;
         }
       }
     }
  }
}
</style>
